import { forwardRef, KeyboardEventHandler } from 'react';
import { Input, InputProps } from './Input';

const allowedCodes = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

export const NumericInput = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref: any) => {
  const handleNumericChars: KeyboardEventHandler<HTMLInputElement> = e => {
    if (!allowedCodes.includes(e.code) && !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Input
      {...props}
      ref={ref}
      onKeyDown={handleNumericChars}
    />
  );
});
