import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogWrapperProps,
  errorIcon,
  Loader,
  successIcon,
  useTheme,
} from '@ytl/common-web';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { PasswordSetResult } from '../shared/@types/passwordSetResult';
import { SetPassword } from '../shared/components/custom/set-password/SetPassword';
import { missingSessionIdBoxConfig } from '../shared/data/boxConfig';
import { getConfig } from '../shared/data/config';
import { PasswordResource } from '../shared/resource/password.resource';
import { usePassword } from '../shared/utils/usePassword';
import { SessionIdCheckState, useSessionIdCheck } from '../shared/utils/useSessionIdCheck';

export const ChangePasswordPage = ({ isForgottenPasswordSetMode }: { isForgottenPasswordSetMode?: boolean }) => {
  const { translation } = useLanguage();
  const navigate = useNavigate();
  const { isSmallResolution } = useTheme();
  const currentUser = useSelector(selectCurrentUser);
  const [changePasswordResultState, setChangePasswordResultState] = useState<PasswordSetResult>(PasswordSetResult.NA);
  const {
    password,
    setPassword,
    passwordAgain,
    setPasswordAgain,
    isLoading,
    setLoading,
    isContinueButtonDisabled,
    hasPasswordError,
    validatePasswords,
    getPasswordHints,
    passwordsAreNotMatching,
  } = usePassword();
  const { sessionIdCheckState, checkSession, tokenEmail, sessionId } = useSessionIdCheck(setLoading);

  const targetUserEmail = useMemo(() => {
    if (isForgottenPasswordSetMode) {
      return tokenEmail;
    }
    if (!currentUser) {
      throw new Error();
    }
    return currentUser.email;
  }, [isForgottenPasswordSetMode, currentUser, tokenEmail]);

  const continueClicked = useCallback(async () => {
    if (!validatePasswords()) {
      return;
    }

    try {
      setLoading(true);
      debugger;
      if (isForgottenPasswordSetMode) {
        await PasswordResource.forgotPassword({
          newPassword: password,
          token: sessionId as string,
        });
      } else {
        await PasswordResource.changePassword({
          accountId: currentUser!.accountId,
          newPassword: password,
        });
      }

      setChangePasswordResultState(PasswordSetResult.Succeeded);
    } catch (err) {
      setChangePasswordResultState(PasswordSetResult.Failed);
    } finally {
      setLoading(false);
    }
  }, [currentUser, isForgottenPasswordSetMode, password, sessionId, setLoading, validatePasswords]);

  const tryAgainClicked = useCallback(() => setChangePasswordResultState(PasswordSetResult.NA), []);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    if (isForgottenPasswordSetMode) {
      // check session first
      if (sessionIdCheckState === SessionIdCheckState.NotChecked) {
        return {
          header: translation.changePassword.header,
          subHeader: translation.initialPasswordSet.subHeaderDuringLoading,
          action: (
            <Button disabled={true}>
              <Loader isLoading={isLoading} />
            </Button>
          ),
        };
      }
      if (sessionIdCheckState === SessionIdCheckState.Missing) {
        return missingSessionIdBoxConfig(translation);
      }
      if (sessionIdCheckState === SessionIdCheckState.CheckedAndInvalid) {
        // invalid session id state
        return {
          ...errorIcon,
          header: translation.changePassword.invalidSession.header,
          subHeader: translation.changePassword.invalidSession.subHeader,
          action: <Button onClick={() => AppRoutes.goToYettel()}>{translation.header.menu.backToYettel}</Button>,
        };
      }
      // other case means it's check and valid
    }

    // on mobile, we display the first state of change password
    if (changePasswordResultState === PasswordSetResult.NA || isSmallResolution) {
      return {
        header: translation.changePassword.header,
        subHeaderHTML: translation.changePassword.subHeader(targetUserEmail),
        action: (
          <Button
            disabled={isContinueButtonDisabled}
            onClick={continueClicked}
          >
            <Loader isLoading={isLoading} />
            {translation.changePassword.saveButton}
          </Button>
        ),
      };
    }

    if (changePasswordResultState === PasswordSetResult.Succeeded) {
      const continueLabel = translation.changePassword.continueToAccount || translation.changePassword.continueToHome;
      return {
        ...successIcon,
        header: translation.changePassword.success.header,
        subHeader: translation.changePassword.success.subHeader(targetUserEmail),
        action: <Button onClick={() => navigate(AppRoutes.homepage)}>{continueLabel}</Button>,
      };
    }

    return {
      ...errorIcon,
      headerHTML: translation.changePassword.error.header,
      subHeader: translation.changePassword.error.subHeader(targetUserEmail),
      action: <Button onClick={tryAgainClicked}>{translation.changePassword.tryAgain}</Button>,
    };
  }, [
    changePasswordResultState,
    continueClicked,
    isContinueButtonDisabled,
    isForgottenPasswordSetMode,
    isLoading,
    navigate,
    sessionIdCheckState,
    targetUserEmail,
    translation,
    tryAgainClicked,
    isSmallResolution,
  ]);

  const mobileDialogConfig = useMemo<null | DialogWrapperProps>(() => {
    if (![PasswordSetResult.Succeeded, PasswordSetResult.Failed].includes(changePasswordResultState)) {
      return null;
    }

    if (changePasswordResultState === PasswordSetResult.Succeeded) {
      return {
        ...successIcon,
        title: translation.changePassword.success.header,
        onClose: () => {},
        content: (
          <>
            <div>{translation.changePassword.success.subHeader(targetUserEmail)}</div>
            <div className="password-page__back-to-app">
              <Button
                variant={'contained'}
                onClick={() => navigate(AppRoutes.homepage)}
              >
                {translation.changePassword.success.backToWeb}
              </Button>
              <Button
                variant={'contained'}
                onClick={() => (window.location.href = getConfig().backToAppUrl)}
              >
                {translation.changePassword.success.backToApp}
              </Button>
            </div>
          </>
        ),
      };
    }

    return {
      ...errorIcon,
      titleHTML: translation.changePassword.error.header,
      content: translation.changePassword.error.subHeader(targetUserEmail),
      okLabel: translation.changePassword.tryAgain,
      onClose: tryAgainClicked,
    };
  }, [changePasswordResultState, navigate, targetUserEmail, translation, tryAgainClicked]);

  useEffect(() => {
    if (isForgottenPasswordSetMode) {
      checkSession();
    } else {
      if (!currentUser) {
        navigate(AppRoutes.errorPage);
      }
    }
  }, [checkSession, currentUser, isForgottenPasswordSetMode, navigate]);

  useLayoutEffect(() => {
    document.documentElement.classList.add('change-password-page');
    return () => {
      document.documentElement.classList.remove('change-password-page');
    };
  }, []);

  return (
    <div className="password-page password-page--change-password">
      <Box
        icon={boxConfig.icon}
        header={boxConfig.header}
        headerHTML={boxConfig.headerHTML}
        subHeader={boxConfig.subHeader}
        subHeaderHTML={boxConfig.subHeaderHTML}
        action={boxConfig.action}
      >
        {(!isForgottenPasswordSetMode ||
          (isForgottenPasswordSetMode && sessionIdCheckState === SessionIdCheckState.CheckedAndValid)) &&
          changePasswordResultState === PasswordSetResult.NA && (
            <SetPassword
              password={password}
              passwordAgain={passwordAgain}
              passwordHints={getPasswordHints(password, passwordAgain)}
              hasPasswordError={hasPasswordError}
              passwordsAreNotMatching={passwordsAreNotMatching}
              setPassword={setPassword}
              setPasswordAgain={setPasswordAgain}
              isLoading={isLoading}
              onEnter={continueClicked}
            />
          )}
      </Box>

      {isSmallResolution && mobileDialogConfig && <Dialog {...mobileDialogConfig} />}
    </div>
  );
};
