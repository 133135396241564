import { Box, BoxProps, Button, Input, onEnter } from '@ytl/common-web';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { useLanguage } from '../../../contexts/languageContext';

export const useMfaEnterAuthenticatorNameView = (onContinue: (name: string) => Promise<void>) => {
  const { translation } = useLanguage();
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const continueClicked = useCallback(async () => {
    if (!name) {
      return;
    }

    try {
      setIsLoading(true);
      await onContinue(name);
    } catch (err) {
      setError(translation.accountSettings.mfa.authenticatorSetup.error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, name, onContinue, translation]);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      header: translation.accountSettings.mfa.authenticatorSetup.title,
      subHeader: translation.accountSettings.mfa.authenticatorSetup.content,
      action: (
        <Button
          disabled={isLoading}
          onClick={() => continueClicked()}
        >
          {translation.accountSettings.mfa.authenticatorSetup.continue}
        </Button>
      ),
      subAction: (
        <Button
          variant="text-undecorated"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          {translation.accountSettings.mfa.authenticatorSetup.cancel}
        </Button>
      ),
    };
  }, [translation, navigate, continueClicked, isLoading]);

  const view = useMemo(
    () => (
      <Box {...boxConfig}>
        <Input
          label={
            <>
              {translation.accountSettings.mfa.authenticatorSetup.nameLabel}
              <InfoIcon className={'icon icon--tooltip'} />
            </>
          }
          name={'name'}
          type={'text'}
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={isLoading}
          errorList={error ? [error] : []}
          onKeyUp={onEnter(continueClicked, () => setError(''))}
        />
      </Box>
    ),
    [boxConfig, translation, name, isLoading, error, continueClicked],
  );

  return {
    view,
  };
};
